/*
 * @Description: 
 * @Author: transparent
 * @Date: 2021-12-31 11:17:14
 * @LastEditTime: 2022-01-06 15:12:17
 * @LastEditors: transparent
 * @FilePath: /hermes-web/src/api/dataManagement.js
 */
import request from '@/utils/request'

export function getDictList(param) {
    return request({
        url: '/admin/dict/queryList',
        method: 'post',
        data: param
    })
}

export function addDict(param) {
    return request({
        url: '/admin/dict/add',
        method: 'post',
        data: param
    })
}

export function editDict(param) {
    return request({
        url: '/admin/dict/edit',
        method: 'post',
        data: param
    })
}

export function deleteDict(param) {
    return request({
        url: '/admin/dict/delete',
        method: 'post',
        data: {
            id: param
        }
    })
}

export function findDetailDict(param) {
    return request({
        url: '/admin/dict/findDetail',
        method: 'post',
        data: {
            id: param
        }
    })
}

export function selectListDict(param) {
    return request({
        url: '/admin/dict/selectList',
        method: 'post',
        data: param
    })
}

//关联子节点
export function associatedChildNode(param) {
    return request({
        url: '/admin/dict/addChildren',
        method: 'post',
        data: param
    })
}