<template>
  <div>
    <el-main>
      <el-form
        class="projectFrom"
        :model="queryParams"
        ref="queryParams"
        label-width="120px"
      >
        <el-form-item label="分类" prop="category">
          <el-select
            v-model="queryParams.category"
            @visible-change="getCategory($event, 'workflow_file_category')"
            filterable
            placeholder="请选择分类"
            clearable
            class="childWidth"
            no-data-text="加载中···"
            @change="categoryChange()"
          >
            <el-option
              v-for="item in categorytList"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="名称" prop="name">
          <el-input
            type="text"
            v-model="queryParams.name"
            placeholder="请输入名称"
            width="200"
            clearable
            @change="nameChange()"
          ></el-input>
        </el-form-item>

        <el-form-item label="启用状态" prop="enabled">
          <el-select
            v-model="queryParams.enabled"
            placeholder="请选择启用状态"
            clearable
            @change="enabledChange()"
          >
            <el-option
              v-for="item in enabledList"
              :label="item.name"
              :value="item.code"
              :key="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <div style="margin-left: 80px; float: right">
          <el-button @click="resetQueryParams">重置</el-button>
          <el-button type="primary" @click="queryWorkflowFileList(1, pageSize)"
            >查询</el-button
          >
          <el-button
            type="primary"
            @click="openDialog('add')"
            v-permission="['admin:processDocument:add']"
            >新增</el-button
          >
        </div>
      </el-form>

      <!-- 表格 -->

      <el-table
        border
        style="width: 100%"
        :data="tableData"
        v-loading="tableLoading"
      >
        <el-table-column label="分类" prop="category">
          <template slot-scope="scope">
            <span>{{ scope.row.category }}</span>
          </template>
        </el-table-column>
        <el-table-column label="名称" prop="name"></el-table-column>
        <el-table-column label="描述" prop="description"></el-table-column>
        <el-table-column label="启用状态" prop="enabled">
          <template slot-scope="scope">
            <span>{{ scope.row.enabled == 1 ? "启用" : "禁用" }} </span>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="200">
          <template slot-scope="scope">
            <el-button
              v-permission="['admin:processDocument:edit']"
              type="primary"
              size="mini"
              @click="openDialog('edit', scope.row.id, scope.row.name)"
              >编辑</el-button
            >
            <el-button
              v-permission="['admin:processDocument:delete']"
              type="primary"
              size="mini"
              @click="deleteWorkflowFile(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="page-info-class">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="this.pageNum"
          :page-sizes="[5, 10, 20, 30, 50]"
          :page-size="this.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="this.total"
        ></el-pagination>
      </div>
    </el-main>

    <!-- 编辑 -->
    <edit
      v-if="editDialog"
      :editDialog="editDialog"
      :type="typeDialog"
      v-on:listenEditClose="listenEditClose"
      :id="dictInfo.id"
      :name="dictInfo.name"
    ></edit>
  </div>
</template>

<script>
import { workflowFileList, deleteWorkflowFile } from "@/api/processDocument";
import { selectListDict } from "@/api/dataManagement";
import edit from "../components/processDocument/edit";

export default {
  name: "processDocument",
  data() {
    return {
      tableData: [],
      editDialog: false, //打开编辑
      buttonControlDialog: false,
      dictInfo: {
        id: "",
        name: "",
      },
      typeDialog: "",
      roleCode: null,
      roleName: null,
      tableLoading: false,
      indexOf: [0],
      spanArr: [],
      total: 0,
      pageNum: 1,
      pageSize: 10,
      pageCount: 1,
      dictList: [],
      queryParams: {},
      enabledList: [
        { name: "启用", code: "1" },
        { name: "禁用", code: "2" },
      ],
      categorytList: [],
    };
  },
  filters: {
    // categoryStatus(value) {
    //     console.log(value)
    //   if (this.dictList.length > 0) {
    //     this.dictList.forEach((item, index) => {
    //       switch (value) {
    //         case value:
    //           return item.name;
    //           break;
    //       }
    //     });
    //   }
    // },
  },
  components: {
    edit,
  },

  mounted() {
    this.queryWorkflowFileList(1, this.pageSize);
  },

  methods: {
    nameChange() {
      this.$forceUpdate();
    },
    categoryChange() {
      this.$forceUpdate();
    },
    enabledChange() {
      this.$forceUpdate();
    },
    resetQueryParams() {
      this.$refs["queryParams"].resetFields(); //重置form
      this.pageNum = 1;
      this.pageSize = 10;
      this.queryWorkflowFileList(1, this.pageSize);
    },
    getCategory(cb, type) {
      if (cb) {
        let info = { group: type };
        selectListDict(info).then((response) => {
          if (response.success) {
            this.categorytList = response.data || [];
          }
        });
      }
    },
    selectListDict(cb, type) {
      if (cb) {
        let info = { group: type };
        selectListDict(info).then((response) => {
          if (response.success) {
            this.dictList = response.data;
            this.tableData.forEach((item, index) => {
              this.dictList.forEach((item1, index1) => {
                if (item.category == item1.value) {
                  item.category = item1.name;
                }
              });
            });
            this.$forceUpdate();
            this.tableLoading = false;
          }
        });
      }
    },
    handleSizeChange(val) {
      this.queryWorkflowFileList(1, val);
    },
    handleCurrentChange(val) {
      if (val > this.pageCount) {
        val = this.pageCount;
      }
      this.queryWorkflowFileList(val, this.pageSize);
    },
    deleteWorkflowFile(id) {
      this.$confirm("确定要删除此条数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: false,
      })
        .then(() => {
          deleteWorkflowFile(id).then((response) => {
            if (response.success) {
              this.queryWorkflowFileList();
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            } else {
              this.$message({
                type: "info",
                message: response.message,
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    queryWorkflowFileList(num, pageSize) {
      let query = {
        ...this.queryParams,
      };
      query.pageNum = num;
      query.pageSize = pageSize;
      this.tableLoading = true;
      workflowFileList(query).then((response) => {
        if (response.success) {
          this.tableData = response.data || [];
          this.pageNum = response.pageNum;
          this.pageSize = response.pageSize;
          this.total = response.total;
          this.pageCount = response.pageCount;
          this.selectListDict(true, "workflow_file_category");
        } else {
          this.total = 0;
        }
      });
    },
    //新增编辑弹窗显示
    openDialog(type, id, name) {
      // console.log(id);
      if (type == "edit") {
        this.typeDialog = "edit";
        this.dictInfo.id = String(id);
        // this.dictInfo.name = name;
      } else if (type == "add") {
        this.typeDialog = "add";
      }
      this.editDialog = true;
    },
    //监听编辑弹窗关闭
    listenEditClose(params) {
      console.log(params);
      const { type, isClose } = params;
      this.editDialog = !isClose;
      if (type == "sure") {
        this.queryWorkflowFileList();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.projectFrom {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.projectFrom .el-form-item {
  width: 20rem;
  flex-shrink: 0;
}
.editDialogs >>> .el-dialog__body {
  text-align: left;
}
.editDialogs ul {
  list-style: none;
  text-align: left;
  line-height: 30px;
}
</style>
