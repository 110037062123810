import request from '@/utils/request'

//条件查询流程文件列表
export function workflowFileList(param) {
    return request({
        url: '/admin/workflow/file/paging-list',
        method: 'post',
        data: param
    })
}

export function addWorkflowFile(param) {
    return request({
        url: '/admin/workflow/file/add',
        method: 'post',
        data: param
    })
}

export function editWorkflowFile(param) {
    return request({
        url: '/admin/workflow/file/edit',
        method: 'post',
        data: param
    })
}

export function deleteWorkflowFile(param) {
    return request({
        url: '/admin/workflow/file/delete',
        method: 'post',
        data:  {
            id: param,
        }
    })
}

export function workflowFileDetail(param) {
    return request({
        url: '/admin/workflow/file/detail',
        method: 'post',
        data:  {
            id: param,
        }
    })
}

 
//条件查询流程文件的选择列表(无分页)
export function selectListWorkflowFile(param) {
    return request({
        url: '/admin/workflow/file/select-list',
        method: 'post',
        data:param
    })
}